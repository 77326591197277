.users {
  display: flex;
  flex-direction: column;

  .cta-container {
    height: 38px;
    display: flex;
    align-items: center;
    padding: 0 8px;
  }

  .submodule-cell {
    padding-left: 16px;
  }

  .screens-cell {
    padding-left: 32px;
  }

  .expand-button-holder {
    width: 20px;
    display: inline-block;
  }

  .expand-button {
    padding: 0;
  }

  .right-cell {
    height: 100%;
    position: relative;

    &:hover {
      .edit-right-button {
        display: inline-flex;
      }
    }

    .edit-right-button {
      position: absolute;
      padding: 1px;
      top: 3px;
      right: -5px;
      display: none;
    }
  }

  .right-cell-users {
    width: calc(100% - 24px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
