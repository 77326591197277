:root {
  --sidebar-width: 200px;
  --primary-color: #0686cf;
  --secondary-color: #f37c38;
  --gray-color: #eee;
  --dark-gray-color: #ddd;
}

html,
body,
#root,
#app {
  height: 100%;
}

body {
  margin: 0;

  .MuiTextField-root,
  .MuiInputBase-root,
  .MuiInputBase-input {
    height: 100%;
    padding: 0;
    border-radius: 0;
    line-height: 20px;
  }

  .MuiInputBase-root {
    font-size: inherit;
    font-weight: bold;

    &.MuiOutlinedInput-root {
      min-height: 20px;
    }
  }

  .MuiOutlinedInput-input {
    padding: 0 4px;
  }

  .MuiAutocomplete-root {
    height: 100%;
  }

  .MuiAutocomplete-noOptions {
    padding: 0 4px;
    color: #ccc;
  }

  .MuiAutocomplete-popper .MuiPaper-root {
    margin-top: 1px;
    border: 1px solid var(--dark-gray-color);
    border-radius: 0;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;

    &.MuiOutlinedInput-adornedEnd {
      padding-right: 24px;
    }

    .MuiAutocomplete-input {
      padding: 0;

      &:first-child {
        padding-left: 4px;
      }
    }

    .MuiAutocomplete-endAdornment {
      right: 0;
    }
  }

  .MuiAutocomplete-tag {
    height: 24px;
  }

  .icon-button {
    min-width: 0;
  }

  .data-grid {
    flex-grow: 1;
    --font-size: 12px;

    .options-cell {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-button {
        position: relative;
        top: -1px;
        margin-right: 2px;
        padding: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .navigation-cell,
    .options-cell,
    .download-cell,
    .rename-cell,
    .gantt-cell {
      &[aria-selected='true'] {
        box-shadow: none;
      }
    }

    .value-cell.align-right {
      text-align: right;
    }
  }
}

body,
body .MuiAutocomplete-paper,
body .MuiTab-root,
body .MuiButton-root,
body .MuiToggleButton-root,
body .MuiInputBase-root,
body .MuiMenuItem-root,
body .MuiTypography-root,
body .MuiAlert-root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
}

#app {
  width: 100%;
  display: flex;
  justify-content: center;
}

.home {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.general-loading-indicator {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
}

.file-input-container {
  position: relative;

  input[type='file'] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}

a {
  text-decoration: none;

  &:any-link {
    color: var(--primary-color);
  }

  &:hover {
    color: var(--secondary-color);
  }
}
