.sidebar {
  width: var(--sidebar-width);
  border-right: 1px solid var(--dark-gray-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top {
    overflow: auto;
  }

  .logo {
    padding: 4px 8px;
  }

  ul {
    margin: 0;
    padding: 0 0 12px;
    list-style: none;
  }

  a {
    display: flex;
    align-items: center;
    padding: 2px 8px;
    text-decoration: none;

    &:any-link {
      color: #000000b4;
    }

    &:hover,
    &.active {
      background: var(--gray-color);
    }

    &.active {
      color: var(--secondary-color);
    }

    &.icon-link {
      display: flex;

      .link-text {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        margin-left: 4px;
      }
    }
  }

  .icon-button {
    padding: 0;
  }

  .sidebar-section-header {
    border-radius: 0;
    color: #fff;
    box-shadow: none;
  }

  .module-lists {
    a {
      padding-left: 12px;
    }
  }

  .table-config-list {
    padding-top: 12px;
    border-top: 1px solid var(--dark-gray-color);
  }
}
