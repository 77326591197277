.export-dialog {
  .input-row {
    margin-bottom: 12px;
  }

  .text-field-container {
    height: 24px;
  }

  .orientation-radio-group {
    align-items: center;
  }

  .email-recipients-list {
    &.ineffective {
      text-decoration: line-through;
      color: #999;
    }
  }

  .add-all-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
