.main {
  width: calc(100% - var(--sidebar-width));
  overflow: auto;

  .goto-object-button {
    width: 22px;
    min-width: 0;
    margin-left: 4px;
    padding: 0;
  }
}

.toolbar {
  display: flex;
  position: absolute;
  top: 8px;
  right: 12px;
  background: #fff;

  .global-cta-item {
    margin-left: 8px;

    &.global-filter-item {
      margin-left: 16px;

      .MuiFormControlLabel-root {
        margin-right: 0;
      }
    }

    &.input-item {
      width: 250px;
      height: 24px;
      position: relative;
      top: -1px;
    }

    &.search-item {
      width: 200px;

      .MuiInputAdornment-root {
        position: relative;
        right: -10px;
      }
    }

    .MuiTextField-root,
    .MuiInputBase-root,
    .MuiInputBase-input {
      border-radius: 4px;
    }
  }

  .icon-button {
    padding: 1px;
  }
}
