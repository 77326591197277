.error-boundary {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .cta-container {
    display: flex;
    margin-right: 8px;

    .MuiButton-root {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
