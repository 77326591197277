.item-view {
  position: absolute;

  &.is-hidden {
    border: 1px solid #0f0;
    opacity: 0.5;
  }

  .item-info {
    height: 11px;
    position: absolute;
    top: 0;
    right: 0;
    background: #fffc;
    line-height: 11px;
    font-size: 9px;
    font-weight: normal;
    color: #0f0;
  }
}

.item-view-type-NeoLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: initial;
  font-size: 11px;
  color: #ccc;
}

.item-view-type-NeoDateField {
  .MuiIconButton-root {
    padding: 0;

    .MuiSvgIcon-root {
      width: 20px;
      color: var(--dark-gray-color);
    }
  }
}

.item-view-type-NeoCheckBox {
  .MuiCheckbox-root {
    width: 100%;
    height: 100%;
    padding: 0;
    color: #ccc;
  }
}

.text-field-item,
.concat-values-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.text-field-item {
  .recalculate-button {
    padding: 0;
    margin-left: 8px;
  }

  .loading-indicator {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }
}

.object-selector-item,
.object-selector-nav-item,
.process-runner-item {
  height: 100%;
  display: flex;
  align-items: center;

  .MuiAutocomplete-root {
    flex-grow: 1;
  }
}

.process-runner-item {
  .run-process-button {
    margin-left: 4px;
    padding: 4px 8px;
    font-size: 12px;
  }
}

.color-selector-item {
  height: 100%;

  .MuiInputBase-root {
    width: 100%;
  }

  .MuiSelect-root.MuiSelect-outlined {
    padding: 0;
    text-overflow: clip;
  }
}

.rectangle-item {
  height: 100%;
  border: 2px solid var(--dark-gray-color);
}

.image-item {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .image-item-controls {
      display: flex;
    }
  }

  &.no-image {
    border: 1px solid var(--dark-gray-color);
  }

  .image-display {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .no-image-icon {
    width: 60px;
    height: 60px;
  }

  .image-item-controls {
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
    background: #fffc;
  }
}

.data-grid-item {
  height: 100%;
  display: flex;
  flex-direction: column;

  .data-grid-item-controls-container {
    height: 32px;
    flex-shrink: 0;
    padding-bottom: 6px;
    display: flex;
    justify-content: space-between;

    .data-grid-item-controls {
      display: flex;
      align-items: flex-end;
    }

    .icon-button {
      margin-right: 4px;
    }
  }

  .process-runner {
    width: 300px;
    height: 22px;
    display: flex;
    align-items: center;
  }

  .process-selector {
    flex-grow: 1;
  }

  .navigation-cell,
  .download-cell,
  .rename-cell {
    padding: 0 2px;

    .goto-object-button,
    .icon-button {
      position: relative;
      top: -1px;
      margin-left: 1px;
    }
  }

  .value-header {
    .rdg-header-sort-name {
      position: relative;
      left: 0;
      transition: left 0.2s;
    }

    &:hover,
    &.is-filter-open,
    &.is-filter-set {
      .rdg-header-sort-name {
        left: 20px;
      }

      .filter-button {
        display: inline-flex;
      }
    }

    &.is-filter-set {
      .filter-button {
        color: var(--primary-color);
      }
    }

    .filter-button {
      position: absolute;
      left: 4px;
      top: 4px;
      display: none;
    }
  }

  .icon-button {
    padding: 1px;
  }
}

.file-list-item {
  position: relative;

  input[type='file'] {
    display: none;
  }

  .drop-area {
    position: absolute;
    top: 36px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--gray-color);
    border: 4px dashed;
    opacity: 0.75;

    .drop-area-icon {
      width: 60px;
      height: 60px;
    }
  }
}

.gantt-item {
  .column-unit-button {
    padding: 2px 8px;
    text-transform: none;
  }

  .rdg-header-row {
    .rdg-cell {
      &:not(.rdg-cell-frozen) {
        padding: 0;
      }
    }
  }

  .gantt-header {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    .top-row,
    .bottom-row {
      height: 50%;
      line-height: 30px;
    }

    .bottom-row {
      display: flex;

      .division {
        border-top: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);

        &:last-child {
          border-right: none;
        }

        &.is-weekend {
          color: var(--secondary-color);
        }
      }
    }
  }

  .gantt-cell {
    position: relative;

    &:not(:last-child) {
      border-right: none;
    }

    &.weekend-cell {
      background: #00000011;
    }
  }

  .gantt-segment-portion {
    height: 100%;
    position: absolute;
    top: 0;
  }
}

.rdg-header-row {
  background: var(--primary-color);
  color: #fff;
}
