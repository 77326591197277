.object-view {
  height: 100%;
  display: flex;
  flex-direction: column;

  header {
    padding: 4px 12px;

    .breadcrumbs {
      width: calc(100% - 720px);
    }

    .current-object-breadcrumb {
      color: var(--secondary-color);
    }
  }

  h2 {
    font-size: 20px;
    margin: 20px 0 8px;
    color: var(--secondary-color);
  }

  .tabs-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 2px solid var(--dark-gray-color);

    .MuiTabs-root {
      min-height: 0;
    }

    .MuiTab-root {
      min-width: 0;
      min-height: 0;
      background: var(--gray-color);
      border-right: 1px solid #fff;
      text-transform: initial;
      opacity: 1;

      &:hover {
        background: var(--dark-gray-color);
      }

      &.Mui-selected {
        background: none;
        color: #fff;
      }
    }

    .MuiTabs-indicator {
      height: 100%;
      z-index: -1;
    }
  }

  .cta-container {
    display: flex;
    align-items: flex-start;
    margin-right: 8px;

    .MuiButton-root {
      margin-left: 8px;
    }
  }

  .object-info-container {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}

.items-container {
  flex-grow: 1;
  position: relative;
  overflow: auto;
}
